var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-card',[_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('ma-date-picker',{staticClass:"pt-2 pr-1",attrs:{"label":"From","past":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}}),_c('ma-date-picker',{staticClass:"pt-2 pr-1",attrs:{"label":"To","past":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}}),_c('v-btn',{staticClass:"mx-2 mt-3",attrs:{"text":"","small":"","dark":"","color":"indigo"},on:{"click":function($event){return _vm.clearFilters()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-broom ")]),_vm._v("clear filters ")],1),_c('v-btn',{staticClass:"mx-2 mt-3",attrs:{"text":"","small":"","dark":"","color":"indigo"},on:{"click":function($event){return _vm.getStatistics()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-update ")]),_vm._v("Update ")],1)],1),(_vm.filterDate != 'clear')?[_c('v-layout',{staticClass:"pa-4",attrs:{"column":""}},[_c('v-layout',{staticClass:"text-body-1 text--secondary",attrs:{"column":""}},[_c('div',[_vm._v(" From "),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.prettyDate(_vm.query1.date1)))])]),_c('div',[_vm._v(" To "),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.prettyDate(_vm.query1.date2)))])])])],1),_c('v-divider')]:_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.statistics,"footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"header.policy_new",fn:function(ref){
var header = ref.header;
return [(_vm.total && _vm.total.policy_new)?[_vm._v(" "+_vm._s(((header.text) + "(" + (_vm.total.policy_new) + ")"))+" ")]:[_vm._v(" "+_vm._s(("" + (header.text)))+" ")]]}},{key:"item.policy_new",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.policy_new || 0)+" ")]}},{key:"item.policy_renewall",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.policy_renewall || 0)+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total || 0)+" ")]}},{key:"item.policy_quoted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.policy_quoted || 0)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [[_c('v-icon',{attrs:{"color":"info"},on:{"click":function($event){return _vm.showResumeSummary(item)}}},[_vm._v("mdi-eye")])]]}},{key:"header.total",fn:function(ref){
var header = ref.header;
return [(_vm.total && _vm.total.total)?[_vm._v(" "+_vm._s(((header.text) + "(" + (_vm.total.total) + ")"))+" ")]:[_vm._v(" "+_vm._s(("" + (header.text)))+" ")]]}},{key:"header.policy_quoted",fn:function(ref){
var header = ref.header;
return [(_vm.total && _vm.total.policy_quoted)?[_vm._v(" "+_vm._s(((header.text) + "(" + (_vm.total.policy_quoted) + ")"))+" ")]:[_vm._v(" "+_vm._s(("" + (header.text)))+" ")]]}},{key:"header.policy_renewall",fn:function(ref){
var header = ref.header;
return [(_vm.total && _vm.total.policy_renewall)?[_vm._v(" "+_vm._s(((header.text) + "(" + (_vm.total.policy_renewall) + ")"))+" ")]:[_vm._v(" "+_vm._s(("" + (header.text)))+" ")]]}}],null,true)}),_c('v-dialog',{attrs:{"overlay":false,"max-width":"700px","transition":"dialog-transition"},model:{value:(_vm.showResume.dialog),callback:function ($$v) {_vm.$set(_vm.showResume, "dialog", $$v)},expression:"showResume.dialog"}},[_c('v-card',[_c('v-container',[[_c('div',{staticClass:"text-center"},[_vm._v("Quote")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.showResume.headers,"items":_vm.showResume.quoted,"items-per-page":5},scopedSlots:_vm._u([{key:"item.polizeStatus",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold",style:({ color: _vm.colorStatus(item.polizeStatus) })},[_vm._v(" "+_vm._s(_vm.getStatusShow(item.polizeStatus))+" ")])]}}],null,true)})],[_c('v-divider',{staticClass:"my-2"}),_c('div',{staticClass:"text-center"},[_vm._v("Renewals")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.showResume.headers,"items":_vm.showResume.renewal,"items-per-page":5},scopedSlots:_vm._u([{key:"item.polizeStatus",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold",style:({ color: _vm.colorStatus(item.polizeStatus) })},[_vm._v(" "+_vm._s(_vm.getStatusShow(item.polizeStatus))+" ")])]}}],null,true)})],[_c('v-divider',{staticClass:"my-2"}),_c('div',{staticClass:"text-center"},[_vm._v("New")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.showResume.headers,"items":_vm.showResume.new,"items-per-page":5},scopedSlots:_vm._u([{key:"item.polizeStatus",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold",style:({ color: _vm.colorStatus(item.polizeStatus) })},[_vm._v(" "+_vm._s(_vm.getStatusShow(item.polizeStatus))+" ")])]}}],null,true)})]],2)],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }